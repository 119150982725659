<template>
	<w-dialog v-model="dialog" max-width="500px" no-padding :title="$t('doctemplates.generate_the_engagement_letter')" @close="$emit('cancel')">
		<w-layout v-if="isValidating" column pt-2 px-2>
			<w-text-info
				:text="
					$t('doctemplates.engagement-letters.generate_engagment_letter_confirmation', {
						folder_path: folderPath,
						model_name: engagementLetterTemplate.name,
						project_name: project.name
					})
				"
			/>
			<w-flex shrink>
				<AccountantSelector v-model="sender" default="me" label="doctemplates.engagement-letters.accounting-firm_legal_representative" />
			</w-flex>
			<w-flex shrink>
				<ClientSelector v-model="receiver" label="doctemplates.engagement-letters.client_legal_representative" />
			</w-flex>
		</w-layout>
		<w-layout v-else-if="loading" align-center class="w-loading-container" column fill-height justify-center style="height: 120px">
			<v-progress-circular color="primary" indeterminate />
		</w-layout>
		<w-layout v-else-if="engagementLetterTemplates.length === 0" column fill-height pa-2>
			<w-text-alert :text="$t('doctemplates.engagement-letters.no_templates')" />
			<w-flex shrink text-xs-right>
				<w-btn @click="openDocumentTemplateWizard()">{{ $t('doctemplates.create_engagement_letter_template') }} </w-btn>
			</w-flex>
		</w-layout>
		<w-layout v-else-if="engagementLetterTemplates.length > 1" column>
			<v-subheader v-text="$t('doctemplates.engagement-letters.select_engagement-letters') + ' :'" />
			<w-list style="width: 100%">
				<w-divider />
				<v-radio-group v-model="engagementLetterTemplateId">
					<template v-for="item in engagementLetterTemplates">
						<w-list-tile :key="item.id" class="pointer" @click="engagementLetterTemplateId = item.id">
							<w-list-tile-action>
								<w-radio :key="item.id" :value="item.id"></w-radio>
							</w-list-tile-action>
							<w-list-tile-content>
								<w-list-tile-title v-t="item.name" />
							</w-list-tile-content>
						</w-list-tile>
						<w-divider :key="`divider-${item.id}`" />
					</template>
				</v-radio-group>
			</w-list>
		</w-layout>
		<w-layout v-if="!loading && !isValidating && engagementLetterTemplates.length > 0" column>
			<v-subheader v-text="$t('doctemplates.engagement-letters.select_folder') + ' :'" />
			<w-flex style="height: 200px">
				<FolderSelector v-model="folder" return-object :virtual-folders="false" />
			</w-flex>
		</w-layout>
		<w-layout v-if="!loading" justify-end px-2 pb-2>
			<template v-if="!isValidating">
				<w-btn flat @click="$emit('cancel')">{{ $t('actions.cancel') }}</w-btn>
				<w-btn :disabled="!folder || !folder.id || !engagementLetterTemplateId" @click="isValidating = true">
					{{ $t('next') }}
				</w-btn>
			</template>
			<template v-else>
				<w-btn flat @click="isValidating = false">{{ $t('previous') }}</w-btn>
				<w-btn :disabled="!sender || !receiver || loadingValidation" :loading="loadingValidation" @click="generateEngagementLetter()"
					>{{ $t('actions.validate') }}
				</w-btn>
			</template>
		</w-layout>
	</w-dialog>
</template>

<script>
import Project from '@/classes/Offers/Project'

import DocumentTemplateModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentTemplateModuleGuard'

export default {
	name: 'EngagementLetterWizard',
	components: {
		AccountantSelector: () => ({
			component: import('@/components/TeamManager/Forms/UserSelector')
		}),
		ClientSelector: () => ({
			component: import('@/components/CustomersManager/Forms/UserSelector')
		}),
		FolderSelector: () => ({
			component: import('@/components/Documents/Forms/FolderSelector')
		})
	},
	mixins: [DocumentTemplateModuleGuard],
	props: {
		project: {
			required: true,
			type: Project
		}
	},
	data: function () {
		return {
			cancelTokens: {
				listEngagementLetters: null
			},
			engagementLetterTemplates: [],
			engagementLetterTemplateId: null,
			dialog: false,
			folder: null,
			isValidating: false,
			loading: true,
			openFolderSelection: false,
			receiver: null,
			sender: null,
			loadingValidation: false
		}
	},
	computed: {
		engagementLetterTemplate: function () {
			const id = this.engagementLetterTemplateId
			if (!id) {
				return undefined
			}

			return this.engagementLetterTemplates.find(template => template.id === id)
		},
		folderPath: function () {
			if (!this.folder) {
				return
			}

			if (!this.folder.path) {
				return `/${this.folder.name}`
			}

			const path = this.folder.path.replace(/ \/ /g, '/').replace(/\/ /g, '/')

			return `${path}${this.folder.name}`
		}
	},
	watch: {
		accountingFirmId: {
			handler: function (newVal) {
				if (!newVal) {
					return
				}

				this.listEngagementLetterTemplates()
			},
			immediate: true
		}
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		generateEngagementLetter: function () {
			this.loadingValidation = true
			this.service
				.generateEngagementLetter(this.accountingFirmId, this.vendorId, this.project.id, {
					folder_id: this.folder.id,
					document_template_id: this.engagementLetterTemplateId,
					receiver_id: this.receiver.id,
					sender_id: this.sender.id
				})
				.then(document => {
					this.$emit('input', document)
					this.loadingValidation = false
				})
		},
		listEngagementLetterTemplates: function () {
			this.loading = true
			const callAnswer = this.service.listDocumentTemplates(this.accountingFirmId, this.cancelTokens.listEngagementLetters)
			this.cancelTokens.listEngagementLetters = callAnswer.cancelTokens

			return callAnswer.promise
				.then(engagementLetterTemplates => {
					this.engagementLetterTemplates = engagementLetterTemplates
				})
				.then(() => {
					if (this.engagementLetterTemplates.length !== 1) {
						return
					}

					this.engagementLetterTemplateId = this.engagementLetterTemplates[0].id

					this.openFolderSelection = true
				})
				.finally(() => {
					this.loading = false
				})
		},
		openDocumentTemplateWizard: function () {
			this.appService.goTo('doc-templates')
		}
	}
}
</script>

<style scoped>
:deep(.v-input--radio-group) {
	margin: 0;
	padding: 0;
}

:deep(.v-input__control) {
	width: 100%;
}
</style>
